.landing {
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing--container {
  display: flex;
  text-align: center;
  height: 100%;
  position: relative;
}

.lcr--content {
  font-family: var(--primaryFont);
  font-style: normal;
}

.lcr--content h1 {
  font-weight: 600;
  font-size: 3.125rem;
  line-height: 110%;
  margin-bottom: 28px;
  background: linear-gradient(93deg, rgba(15, 12, 41, 1) 0%, rgba(48, 43, 99, 1) 70%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  padding: 0 10px;
}

.lcr--content p {
  font-size: 0.813rem;
  color: #a8a8a8 !important;
  place-self: center;
  text-transform: uppercase;
  letter-spacing: 0.428571em;
  padding: 0 30px;
}

.lastname{
  display: none;
}

@media (min-width: 600px) {
  .lcr--content p {
    padding: 0 50px;
  }

  .lcr--content h1 {
    margin-top: 30px;
  }
}

@media (min-width: 985px) {

  .landing {
    padding-top: 90px;
  }

  .lcr--content h1 {

    font-size: 4rem;
    line-height: 110%;
    -webkit-text-fill-color: transparent;
  }

  .lcr--content p {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {

  .lastname{
    display: inline;
  }

  .landing {
    padding-top: 0px;
  }
}