.container {
    font-family: var(--primaryFont);
    padding-top: 32px;
    margin-top: 0px;
    gap: 2rem;
    max-width: 985px !important;
    justify-self: center;
}

.projecSectionTitle {
    padding-top: 35px !important;
    padding-bottom: 45px !important;
    text-align: center;
    font-size: 32px;
}

.projectContainer {
    background-color: #F1F5F9;
    padding: 30px;
    border-radius: 12px;
    max-width: 370px;
    transition: 400ms ease-in-out;
}

.projectContainer:hover {
    transform: scale(1.02);
    transition: 400ms ease-in-out;
    background-color: rgba(226, 232, 240, .7);
}

.projectsContainer {
    display: flex;
    gap: 2rem;
    justify-self: center;
    max-width: 980px;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 15px;
}

.projectImage {
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 15px;
    object-fit: fill;
    border-radius: 12px;
    height: 170px;
    box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.56);
    -webkit-box-shadow: 0px 10px 27px -7px rgba(0, 0, 0, 0.56);
    -moz-box-shadow: 0px 10px 27px -7px rgba(0, 0, 0, 0.56);
    box-shadow: 0px 10px 27px -7px rgba(0, 0, 0, 0.56);
}

.projectTitle {
    font-size: 17px;
    letter-spacing: -.025em;
    font-weight: 500;
    margin-bottom: 5px;
}

.projectDescription {
    font-size: 13px;
    font-weight: 200;
    line-height: 20px;
}

@media (min-width: 601px) {
    .projectImage {
        max-width: 100%;
        max-height: 100%;
        object-fit: fill;
        border-radius: 12px;
        height: 235px;
    }

    .projectTitle {
        font-size: 20px;
    }

    .projectDescription {
        font-size: 16px;
        font-weight: 200;
        line-height: 24px;
    }

    .projectContainer {
        max-width: 450px;
        min-height: 390px;
    }
    .projecSectionTitle {
        padding-top: 3rem;
    }

}

@media (min-width: 985px) {

    .projecSectionTitle {
        font-size: 40px;
        text-align: start;
        padding-left: 24px !important;
    }

    .projectsContainer {
        display: flex;
        gap: 2rem;
        justify-self: center;
        max-width: 980px;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
    }
}