.about {
  min-height: fit-content;
  position: relative;
  padding: 0 40px;
  max-width: 985px !important;
}

.about-body {
  padding-top: 50px;
  height: fit-content;
}

.about-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  height: fit-content;
}

.about-description>h2 {
  margin-bottom: 23px;
  font-size: 2rem;
  font-family: var(--primaryFont);
  align-self: center;
}

.about-description>p {
  font-size: 1rem;
  font-family: var(--primaryFont);
  line-height: 1.5rem;
}

.resumeLink{
  color: #64748B;
  padding-top: 20px;
}

.aboutArrow{
  transition: transform 0.3s ease-in-out;
  vertical-align: middle;
}

@media (min-width: 601px) {
  .about {
    margin-top: 50px;
    padding: 0 50px !important;
  }
  .about-body {
    padding-top: 0px;
  }

}

@media (min-width: 985px) {
  .about {
    max-width: 985px !important;
    padding: 0 24px !important;
  }

  .about-description>h2 {
    margin-bottom: 40px;
    font-size: 2.5rem;
    align-self: flex-start;
  }

  .about-description>p {
    font-size: 1.25rem;
    font-family: var(--primaryFont);
  }
}