html {
  scroll-behavior: smooth;
}

.lcr--navbar {
  justify-items: center;
  border-radius: 1000px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0); 
  margin-top: 35px;
  transition: background-color 0.3s, backdrop-filter 0.3s;
  z-index: 1000; 
  background: transparent; 
}

.lcr--navbar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px); 
  transition: opacity 0.3s;
  opacity: 0;
  border-radius: inherit;
  z-index: -1; 
}

.lcr--navbar.scrolled::before {
  opacity: 1; 
}

.lcr--navbar ul {
  display: flex;
  font-family: var(--primaryFont);
  color: #666666;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  padding: .5rem 20px;
}

.lcr--navbar ul a {
  color: #334155;
  font-size: 14px;
  text-decoration: none; /* Eliminar el subrayado */
  transition: color 0.3s;
}

.lcr--navbar li {
  list-style-type: none;
  transition-timing-function: cubic-bezier(.4, 0, 2.1);
  transition-duration: .3s;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transform: translate(0), var(0) rotate(0) skew(0) skewY(0) scaleX(1) scaleY(1);
}

@media (min-width: 600px) {

  .lcr--navbar {
    padding: 10px 20px;
    margin-top:70px;
  }

  .lcr--navbar ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    padding: 0;
  }
}

@media (min-width: 985px) {

  .lcr--navbar {
    padding: 10px 20px;
    margin-top:120px;
  }

  .lcr--navbar ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    padding: 0;
  }
}

@media (min-width: 1200px) {
  .lcr--navbar {
    margin-top:50px;
  }

  .lcr--navbar ul a{
    font-size: 16px;
  }
}
