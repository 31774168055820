.education {
  min-height: fit-content;
  max-width: 985px !important;
  padding: 0 !important;
}

.education-body {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.education-description {
  width: 100%;
  flex: 0.65;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 35px;
  padding: 0 16px;
}

.education-description>h1 {
  font-size: 32px;
  font-family: var(--primaryFont);
  margin-bottom: 30px;
  align-self: center;
}

.education-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100px;
  padding: 1.5rem;
  border-radius: 20px;
  margin-bottom: 1.5rem;
  transition: background-color 200ms ease-in-out;
}

.education-card:hover {
  transform: scale(1.02);
  transition: 400ms ease-in-out;
  background-color: rgba(226, 232, 240, .7);
}

.educard-img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.educard-img>img {
  width: 25px;
}

.education-details {
  margin-left: 0.6rem;
}

.education-details>h6 {
  font-family: var(--primaryFont);
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 5px
}

.education-details>h4 {
  font-family: var(--primaryFont);
  font-size: 1rem;
  font-weight: 600;
}

.education-details>h5 {
  font-family: var(--primaryFont);
  font-size: 13px;
  font-weight: 400;
}

.education-image {
  box-sizing: border-box;
  padding: 1rem;
  flex: 0.35;
  margin-top: 1rem;
  pointer-events: none;
}

.education-image>img {
  width: 100%;
}

@media (min-width: 601px) {
  .education-description {
    padding: 0 50px;
    padding-top: 3rem;
    margin-top: 0;
  }

  .education-card {
    height: 80px;
  }

  .educard-img {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .educard-img>img {
    width: 36px;
  }
}

@media (min-width: 985px) {
  .education-description>h1 {
    font-size: 3rem;
    margin-bottom: 2rem;
    align-self: flex-start;
  }

  .education-card {
    height: 140px;
    width: 90%;
  }

  .education-details>h6 {
    font-size: 16px;
  }

  .education-details>h4 {
    font-size: 20px;
  }

  .education-details>h5 {
    font-size: 18px;
  }

  .education-description {
    padding: 24px
  }
}