.experience {
  min-height: fit-content;
}

.experience-description {
  width: 100%;
  flex: 0.65;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 2rem;
  margin-top: 50px;
  align-items: center;
}

.experience-description>h1 {
  font-size: 2rem;
  font-family: var(--primaryFont);
  margin-bottom: 2rem;
  align-self: center;
  z-index: 10;
}

.experience-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 100px;
  padding: 1.5rem;
  border-radius: 20px;
  margin-bottom: 1.5rem;
  transition: background-color 200ms ease-in-out;
  place-items: center;
}

.experience-card:hover{
  transition: 300ms ease-in-out;
  background-color: rgba(226, 232, 240, .7);
}

.expcard-img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.expcard-img>img {
  width: 25px;
  pointer-events: none;
}

.experience-details {
  margin-left: 0.6rem;
}

.experience-details>h4 {
  font-family: var(--primaryFont);
  font-size: 16px;
  font-weight: 600;
}

.experience-details>h5 {
  font-family: var(--primaryFont);
  font-size: 13px;
  font-weight: 400;
}

.experience-image {
  box-sizing: border-box;
  padding: 1rem;
  flex: 0.35;
  margin-left: 7%;
  margin-top: 1rem;
  pointer-events: none;
}

.experience-image>img {
  width: 100%;
  pointer-events: none;
}

@media (min-width: 601px) {
  .experience-body {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .experience {
    min-height: 100%;
    padding: 0 50px !important;
  }

  .experience-description {
    flex: 1;
    margin: auto;
  }

  .experience-description>h1 {
    font-size: 32px;
  }

  .experience-card {
    height: 80px;
  }

  .expcard-img {
    width: 55px;
    height: 55px;
  }
  .expcard-img>img {
    width: 36px;
  }
}

@media (min-width: 985px) {
  .experience {
    max-width: 985px !important;
    padding: 0 24px !important;
  }
  .experience-card {
    width: 90%;
    height: 140px;
  }
  .experience-description>h1 {
    font-size: 40px;
    font-family: var(--primaryFont);
    margin-bottom: 2rem;
    align-self: flex-start;
  }
  .experience-details>h4 {
    font-size: 20px;
  }
  
  .experience-details>h5 {
    font-size: 18px;
  }
}
