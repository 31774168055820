:root { 
  --gradient-color: rgb(248, 250, 252);
}
  
  .skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: fit-content;
  padding-top: 30px;
}

.marquee-container::before{
  background: linear-gradient(to right, var(--gradient-color), transparent);
  content: "";
  height: 180px;
  top: 34px;
  position: absolute;
  width: 30px;
  z-index: 2;
  pointer-events: none;
  touch-action: none;
}
.marquee-container::after{
   background: linear-gradient(to left, var(--gradient-color), transparent);
  content: "";
  height: 180px;
  top: 34px;
  position: absolute;
  width: 30px;
  z-index: 2;
  pointer-events: none;
  touch-action: none;
  right: 0;
} 

.skillsHeader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.skillsHeader h2 {
  font-family: var(--primaryFont);
  font-style: normal;
  font-size: 32px;
  text-align: center;
}

.skillsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  padding: 0rem;
}

.skill--scroll {
  width: 100%;
  margin: 0 1rem;
}

.skill--box {
  background-color: #f1f5f9;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  width: 160px;
  height: 160px;
  margin: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  transition: 300ms ease-in-out;
}

.skill--box:hover {
  transform: scale(1.15);
}

.skill--box > img {
  height: 50px;
  pointer-events: none;
}

.skill--box h3 {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  margin-top: 1rem;
}

.marquee {
  padding: 1rem 0;
}
.marquee-container{
overflow-y: hidden;
}


@media (min-width: 601px) {
  .skills{
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 3rem;
  }
  .skill--box {
    width: 135px;
    height: 135px;
    margin: 1.2rem;
    padding: 2rem 1rem;
  }

  .skill--box > img {
    height: 40px;
  }

  .skill--box h3 {
    font-size: 16px;
  }
}

@media (min-width: 985px) {
  .skills{
    padding-left: 50px;
    padding-right: 50px;
    max-width: 1020px;
    margin: auto;
  }
  .skill--box {
    width: 160px;
    height: 160px;
    margin: 1.5rem;
    padding: 2rem 1rem;
  }

  .skill--box > img {
    height: 60px;
  }

  .skill--box h3 {
    font-size: 20px;
    margin-top: 1rem;
  }
  .skillsHeader h2 {
    font-size: 40px;
  }
}
